<script>
export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      input: null
    };
  },
  mounted() {
    if (this.input && this.input.hasAttribute('autofocus')) {
      this.input.focus();
    }
  }
};
</script>

<template>
  <input
    class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    ref="input"
  >
</template>
